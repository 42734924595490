import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import VueLazyload from 'vue-lazyload';
import VueCookies from 'vue-cookies';

axios.defaults.headers.common['token'] = localStorage.getItem('token');
Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.use(ElementUI);
Vue.use(VueCookies);
Vue.prototype.imgUrlPrefix = 'http://pic.pusite.icu/';
Vue.prototype.getImgUrl = function (img) {
    return this.imgUrlPrefix + img;
};
Vue.use(VueLazyload, {
    preload: 1.3, // 表示lazyload元素距离底部距离百分比
    loading: require('@/assets/loading.gif'), // 正在加载时显示的图片路径
    error: require('@/assets/404.png'), // 图片加载失败时显示的图片路径
    attempt: 5, // 图片加载失败后重试次数，默认3
});
new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});
