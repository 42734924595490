import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
        meta: {
            title: '首页',
        },
    },
    {
        path: '/good/:id',
        name: 'good',
        component: () => import('../views/ItemView.vue'),
        meta: {
            title: '商品详情页',
        },
    },
    {
        path: '/certify',
        name: 'certify',
        component: () => import('../views/CertifyView.vue'),
        meta: {
            title: '商家入驻页',
        },
    },
    {
        path: '/company-auth',
        name: 'company-auth',
        component: () => import('../views/CompanyAuthView.vue'),
    },
    {
        path: '/personal',
        name: 'personal',
        component: () => import('../views/PersonalView.vue'),
        meta: {
            title: '个人中心',
        },
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('../views/OrderView.vue'),
        meta: {
            title: '订单',
        },
    },
    {
        path: '/type/:id',
        name: 'type',
        component: () => import('../views/TypeView.vue'),
        meta: {
            title: '类别',
        },
    },
    {
        path: '/shop/:id',
        name: 'shop',
        component: () => import('../views/ShopView.vue'),
        meta: {
            title: '店铺',
        },
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('../views/SearchView.vue'),
    },
    {
        path: '/car',
        name: 'car',
        component: () => import('../views/CarView.vue'),
    },
    {
        path: '/chat/:id',
        name: 'chat',
        component: () => import('../views/ChatView.vue'),
    },
];

const router = new VueRouter({
    // mode: 'history',
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
});

export default router;
